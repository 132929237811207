.page-template-tpl-new-formpage-v6a
	background-color: #094a78

#step-8-form-tnc .tooltip.top .tooltip-inner
	width: 420px
	max-width: 420px
	font-size: 11px
	text-align: left
@media (max-width: 767px)
	#step-8-form-tnc .tooltip.top .tooltip-inner
		width: 300px
		max-width: 300px
.eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side .step-form .form-check .form-control.compliance__tickbox
	width: 100%

.eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .right-side .step-form .form-check
	align-items: flex-start	

// CUSTOM CSS ON OPTIMIZELY
.page-template-tpl-new-formpage-v6 
	background: #094a78

.step-option-item__label:hover .formstep .tooltip, .step-option-item:hover .formstep .tooltip 
	right: -9999px

.eng__main-background 
	left: -9999px

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-1__A__image 
	background-size: 16px  

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-1__B__image,
.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-1__C__image
	background-size: 22px 

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-3__A__image,
.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-3__B__image,
.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-5__A__image,
.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-5__B__image,
.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-6__A__image,
.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-6__B__image
	background-size: 24px 

.page-template-tpl-new-formpage-v6 .eng__main-content,
.page-template-tpl-new-formpage-v6a .eng__main-content
	background: url(https://asset.alternativemedia.com.au/wp-content/uploads/sites/2/2019/03/07052312/main_background1.jpg)
	background-repeat: no-repeat
	background-size: cover
	background-position: center right

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-option-item__text 
	font-size: 20px !important

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-option-item__image 
	width: 45px 
	height: 45px 

.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label 
	height: 160px 
	width: 155px 

  
@media (max-width: 991px)
	.page-template-tpl-new-formpage-v6 .eng__main-content,
	.page-template-tpl-new-formpage-v6a .eng__main-content 
		padding-top: 0
		margin-top: 0 
  
	.eng__main-content .eng__stepper-container .eng__stepper__step .eng__cta-bubble 
		left: -85px 
  
	.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label 
		justify-content: center 
  
	.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-option-item__image 
		margin-top: 0 
  

@media (max-width: 767px)
	.page-template-tpl-new-formpage-v6,
	.page-template-tpl-new-formpage-v6a
		background: #fff
  
	.eng__main-content .eng__stepper-container 
		min-height: auto 
		padding: 20px 0
  
	.page-template-tpl-new-formpage-v6 .eng__main-content,
	.page-template-tpl-new-formpage-v6a .eng__main-content 
		background: none
  
	.eng__main-content .eng__stepper-container .eng__stepper__step.step-3 .eng__prev-step,
	.eng__main-content .eng__stepper-container .eng__stepper__step.step-4 .eng__prev-step, 
	.eng__main-content .eng__stepper-container .eng__stepper__step.step-5 .eng__prev-step,
	.eng__main-content .eng__stepper-container .eng__stepper__step.step-6 .eng__prev-step 
		margin-bottom: 0 
  
	.eng__main-content .eng__stepper-container .eng__stepper__step.step-1 .step-options-container .step-option-item .step-option-item__label 
		height: auto 
		width: auto 
	
	.eng__main-content .eng__stepper-container .eng__stepper__step.step-1 .step-options-container .step-option-item .step-option-item__label .step-option-item__image 
		width: 50px 
  	.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-1__A__image:before
		background-size: 16px
	.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-option-item__image:before, .eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-option-item__image:after
		background-size: 24px

@media (max-width: 480px)
	.eng__main-content .eng__stepper-container .eng__stepper__step.step-1 .step-options-container .step-option-item .step-option-item__label .step-option-item__image 
		width: 55px 
	
  
@media (max-width: 320px)
	.eng__main-content .eng__stepper-container .eng__stepper__step.step-1 .step-options-container .step-option-item .step-option-item__label .step-option-item__image 
		width: 45px
		float: left
	.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container.horizontal-on-mobile .step-option-item .step-option-item__label .step-option-item__text 
		float: right
		margin-left: 10px
		width: 175px
		margin-top: 5px
	.step-option-item__label:before,
	.step-option-item__label:after,
	.step-options-container:before,
	.step-options-container:after 
		clear: both
	.step-option-item__label:after,
	.step-options-container:after
		content: ""
		display: block	
	.navbar-light .navbar-brand
		float: left
		display: block
		width: 50%
	.eng__navbar-custom__phone
		float: right
	#navbar.navbar,
	.step-option-item__label 
		display: block
	
	.eng__main-content .eng__stepper-container .eng__stepper__step.step-3 .step-options-container .step-option-item .step-option-item__label,
	.eng__main-content .eng__stepper-container .eng__stepper__step.step-5 .step-options-container .step-option-item .step-option-item__label
	.eng__main-content .eng__stepper-container .eng__stepper__step.step-6 .step-options-container .step-option-item .step-option-item__label
		height: 130px
		width: 100%
		padding-top: 20px
	.eng__main-content .eng__stepper-container .eng__stepper__step.step-3 .step-options-container .step-option-item,
	.eng__main-content .eng__stepper-container .eng__stepper__step.step-5 .step-options-container .step-option-item,
	.eng__main-content .eng__stepper-container .eng__stepper__step.step-6 .step-options-container .step-option-item
		margin: 0 10px
		float: left
		width: 43%
		margin-top: 25px
	.eng__main-content .eng__stepper-container .eng__stepper__step.step-3 .eng__next-step, 
	.eng__main-content .eng__stepper-container .eng__stepper__step.step-5 .eng__next-step, 
	.eng__main-content .eng__stepper-container .eng__stepper__step.step-6 .eng__next-step
		margin-top: 40px
	.eng__main-content .eng__stepper-container .eng__stepper__step.step-3 .step-option-item__label, 
	.eng__main-content .eng__stepper-container .eng__stepper__step.step-5 .step-option-item__label, 
	.eng__main-content .eng__stepper-container .eng__stepper__step.step-6 .step-option-item__label
		text-align: center
		padding-top: 25px
	.eng__main-content .eng__stepper-container .eng__stepper__step .step-options-container .step-option-item .step-option-item__label .step-option-item__image
		margin: 0 auto 15px
		
	.eng__navbar-custom 
		padding-top: 15px
	.eng__stepper__step.step-2 .input-group-prepend,
	.eng__stepper__step.step-8 .input-group-prepend
		display: block
		float: left
		width: 20%
	.eng__stepper__step.step-2 .input-group-text,
	.eng__stepper__step.step-8 .input-group-text
		display: block
		padding: 12px
	.eng__stepper__step.step-2 #postcode-container #postcode,
	.eng__stepper__step.step-8 .form-control
		float: right
		width: 80%
	.eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .left-side, .eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .left-side
		display: block
		text-align: center
		padding: 20px 30px
	#step2 .eng__next-step 
		position: relative !important
	.eng__main-content .eng__stepper-container .eng__stepper__step.step-2, .eng__main-content .eng__stepper-container .eng__stepper__step.step-8 
		display: block
	.eng__main-content .eng__stepper-container .eng__stepper__step.step-2 .left-side, .eng__main-content .eng__stepper-container .eng__stepper__step.step-8 .left-side
		margin-bottom: 0	
	.page-template-tpl-new-formpage-v6 .eng__main-content,
	.page-template-tpl-new-formpage-v6a .eng__main-content 
		padding-top: 0

.radio-options.file-upload label 
	display: block
	width: 155px
	margin: 0 auto 20px
	border-radius: 4px
	background: #f26522
	color: #fff
	text-align: center
	padding: 13px
	border: none
	outline: none
	font-weight: normal
	cursor: pointer
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	box-shadow: 0 6px 8px rgba(242,101,34,0.5);

	img
		margin-right: 5px

	&:hover
		box-shadow: 0 10px 18px rgba(242,101,34,0.5);
    
.radio-options #bill_upload 
	position: absolute
	clip: rect(0,0,0,0)
	pointer-events: none
	top: 0
	left: 0
.radio-options.file-upload 
	padding: 0 60px
	.progress
		height: 25px
	.progress-bar
		background-color: #f26522
		text-indent: -999999px
	#submit__upload-btn
		background: #0a4c7b
		border: 0
		color: #fff
		padding: 10px 30px
		border-radius: 4px
		float: left
		cursor: pointer
	.file__upload-name
		float: right
		font-size: 12px
		margin-top: 10px
		img
			margin-right: 10px
	.upload__btn-wrapper
		margin: 20px 0 30px
		text-align: left
		display: none
	.upload__btn-wrapper.d-block
		display: block
		
.upload__msg-wrapper
	padding: 30px
	max-width: 550px
	margin: 0 auto
	background: #f6f6f6
	border-radius: 10px
	font-size: 14px
	position: relative
.upload__msg-wrapper:after
	content: ""
	display: block
	width: 20px
	height: 20px
	background: #f26522
	position: absolute
	top: 0
	border-radius: 0px 4px 0 4px
	right: 0
	
.behind__bg
	position: absolute
	right: 80px
	bottom: 155px
.wrap__border
	border-right: 1px solid #e5e3e3
	h4
		font-family: "ProximaNova-ExtraBold",sans-serif
		color: #0a4c7b
		margin: 0
.bold__text
	font-weight: bold
	font-size: 13px
@media (max-width: 991px)
	.behind__bg
		display: none
	.upload__msg-step
		padding: 0 30px
		
@media (max-width: 767px)
	.wrap__border
		margin-bottom: 20px
		border: none
		

.error__upload-msg
	max-width: 440px
	background: #fff
	left: 0
	position: absolute
	margin: 0 auto
	right: 0
	z-index: 1000
	h3
		font-size: 24px
		font-family: "ProximaNova-ExtraBold",sans-serif
		color: #f26522
	p
		font-size: 14px
	a
		color: #0a4c7b
		font-weight: bold
.error__upload-msg-bg
	background: rgba(0,0,0,0.5)
	position: absolute
	width: 100%
	height: 100%
	z-index: 100
	top: 0
	
#success__upload-wrapper,
#great__upload-wrapper,
#save__upload-wrapper,
#finish__text
	display: none

#finish__text
	float: right
	margin-left: 10px 
	

.eng__v2__main-content
	background: url(https://asset.alternativemedia.com.au/wp-content/uploads/sites/2/2019/03/07052312/main_background1.jpg)
	background-repeat: no-repeat
	background-size: cover
	background-position: center right



.page-template-tpl-new-formpage-v6a
	.file__upload-name
		width 100%
		text-align: center

	#step10 .eng__prev-step button
		visibility: hidden
		opacity 0
		pointer-events: none

	&.remove-phone .eng__navbar-custom__phone
		display none



	#step9 h6
		font-size: 1.05rem
		font-weight: 600
		display none
		margin: -5px auto 15px
		text-align: center
		padding: 0 10px

	&.show-bill-message #step9 h6
		display block

	
	.form-group.step-form .input-group
		display block 
		padding-left 0
		box-shadow none !important
		margin-bottom: 15px

		.input-group-prepend
			position absolute
			left 0
			top 0
			height 50px
			width 40px
			z-index 5

		input
			width 100%
			box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)
			padding-left 43px !important
			float none !important

		.tooltip
			position static !important
			margin 0 0 5px

			.tooltip-arrow
				display none

			.tooltip-inner
				padding 0
				color #ff0000
				background transparent
				width 100%
				max-width: none
				text-align: left

	.step-info-message
		display none !important

	.eng__navbar-custom .eng__navbar-custom__phone .eng__navbar-custom__phone-details .phone-days
		font-size 16px

		@media (max-width 991px)
			font-size 13px

	&.uploadTriggered .formstep
		.eng__prev-step
			visibility: hidden
			opacity 0
			pointer-events none